<template>
  <div class="page-wrapper">
    <div class="section main small">
      <h2 class="main-heading">Wyraź swój sprzeciw!</h2>
      <p>Od lat kolejne prawicowe rządy pompują publiczne pieniądze w stronę bankierów i deweloperów, którzy dzięki temu rozdawnictwu mają rok do roku rekordowe zyski. Dosypywanie pieniędzy do kredytów nie rozwiązuje kryzysu mieszkaniowego! Sprawia to tylko, że mieszkania w zastraszającym tempie drożeją, a ludzi nadal nie stać na dach nad głową i możliwość założenia rodziny.</p>

    </div>


    <div class="section">

      <div class="row padding-bottom">

        <div class="column">
          <div class="img-container">
            <img src="../../img/zandberg.jpg?quality=75" alt="Adrian Zandberg - poseł Razem." class="photo">
          </div>
        </div>

        <div class="column">
          <div class="quote"><span class="quote-marker">„</span>Projekt Bezpieczny Kredyt 0 proc.? Równie dobrze moglibyśmy wcisnąć przycisk "przelew" i przelać te miliardy złotych bezpośrednio do spółek deweloperskich!

          </div>
          <div class="quote-description"><strong><a href="https://twitter.com/ZandbergRAZEM" target="_blank">Adrian Zandberg</a></strong> - poseł Razem</div>
        </div>
      </div>

    </div>
    <div class="highlight-simple">
      <h2>Zgadzasz się? <router-link to="/podpisz-sie">Podpisz się</router-link> pod nasza inicjatywą! </h2>
    </div>

    <div class="highlight">
      <p>Jedyne rozwiązanie, które realnie działa, to państwo, które buduje mieszkania na tani wynajem. </p>
          <a href="https://partiarazem.pl/aktualnosci/2019/09/30/polityka-mieszkaniowa-razem" target="_blank"><div class="button">Przeczytaj nasz program mieszkaniowy</div></a>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.img-project-1 {
  object-position: 100% 35%;
}

.img-container {
  .photo {
    width: 100%;
    border-radius: 30px;
  }
}

.quote {
  font-size: var(--fontSize-4);
  font-family: var(--fontFamilyQuote);
  font-weight: var(--fontWeight-normal);

  .quote-marker {
    font-size: var(--fontSize-8);
    line-height: 0;
    padding: 0 var(--spacing-4) 0 0;
    color: var(--color-primary);
  }
}

.quote-description {
  text-transform: uppercase;
  padding: var(--spacing-4) 0;
  font-family: var(--fontFamilyQuote);
  font-size: var(--fontSize-2);
}

.accent {
  color: var(--color-primary);
}

ul {
  list-style-position: inside;
  list-style-type: square;

  li {
    font-size: var(--fontSize-2);
    margin-bottom: var(--spacing-1);
  }
}

.page-wrapper .section.main {
  padding-bottom: var(--spacing-4);
}


@media (max-width: 74rem) and (min-width: 54rem) {

  .quote {
    font-size: var(--fontSize-2);
  }
}
</style>
