import { createWebHistory, createRouter } from 'vue-router';

import Main from './components/pages/Main.vue';
import Project from './components/pages/Project.vue';
import Support from './components/pages/Support.vue';

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  routes: [{
    path: '/',
    name: 'Main',
    component: Main,
  }, {
    path: '/wyraz-sprzeciw',
    name: 'Project',
    component: Project,
  }, {
    path: '/podpisz-sie',
    name: 'Support',
    component: Support,
  }, {
    path: '/:pathMatch(.*)*',
    component: Main,
    beforeEnter(_) {
      window.location = '/';
    },
  }],
});

export default router;
