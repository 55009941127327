<template>
  <div class="page-wrapper">
    <div class="section main small-padding-bottom">
      <h2 class="main-heading">Poprzyj!</h2>
      <p class="headline">Wyraź swój sprzeciw wobec pompowania publicznych miliardów w stronę bankierów i deweloperów!</p>
    </div>
    <div class="section wider no-padding-top">
      <Form></Form>
    </div>
  </div>


</template>

<script>
import Form from '../Form.vue'

export default {
  components: {
    Form
  }
};
</script>
