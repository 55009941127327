<template>
  <div class="page-wrapper">
    <div class="section main">
      <h2 class="main-heading">STOP pompowaniu publicznych pieniędzy do kieszeni bankierów i deweloperów!</h2>
      <p class="headline">Kampania przeciw ustawie Bezpieczny Kredyt 0 proc.</p>
      <router-link to="/podpisz-sie"><div class="button">Podpisz się!</div></router-link>
    </div>

    <img src="../../img/img5.jpg?quality=75&width=1920" class="divider img-main-1">

    <div class="section thin">
      <h3>Dlaczego jesteśmy przeciwni ustawie Bezpieczny Kredyt 0 proc.?</h3>
      <p>Od lat kolejne prawicowe rządy chcąc rozwiązać kryzys na rynku mieszkaniowym idą po linii najmniejszego oporu: pompując publiczne pieniądze do kieszeni bankierów i deweloperów. Efekt jest taki: ceny mieszkań szybują, a ludzi nadal nie stać na własne lokum. </p>
      <router-link to="/wyraz-sprzeciw"><div class="button">Czytaj więcej</div></router-link>
    </div>

    <div class="highlight">
      <div class="heading">41%</div>
      <div class="subheading">o tyle podrożały mieszkania w Polsce w przeciągu dwóch ostatnich lat!</div>
    </div>

    <div class="section wider">
      <div class="row">
        <div class="column">
          <img src="../../img/img3.jpg?quality=50" class="img-main-2">
        </div>
        <div class="column">
          <h3>Pomóż w zbiórce</h3>
          <p> Chcesz zatrzymać to szaleństwo? Pomóż nam w zbiórce podpisów! Podpisz się on-line! Informację o zbiórce przekaż swoim znajomym, a my podczas debaty w Sejmie i Senacie nad projektem BK0% przedstawimy je rządowi. Dlatego im więcej podpisów, tym lepiej!
</p>

          <router-link to="/podpisz-sie"><div class="button">Podpisz się!</div></router-link>
        </div>
      </div>
    </div>

    <div class="section wider">
      <div class="row">
        <div class="column">
          <h3>Wesprzyj finansowo</h3>
          <p>Kampania taka jak ta wymaga środków. Możesz wesprzeć nasze starania o normalny rynek mieszkaniowy w Polsce! Liczy się każda kwota.</p>
          <a href="https://wesprzyj.partiarazem.pl" target="_blank"><div class="button">Wspieram!</div></a>
        </div>
        <div class="column">
          <img src="../../img/img2.jpg" class="img-main-2">
        </div>
      </div>
    </div>
  </div>

</template>

<style lang="scss">
.img-main-1 {
  object-position: 50% 35%;
}

.img-main-2 {
  width: 100%;
  border-radius: 20px;
}
</style>
