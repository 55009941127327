<template>
  <nav class="mobile">
    <div class="logo-container">
      <router-link to="/"><img src="../img/logo.png" alt="Logo 0% sensu" class="logo"></router-link>
    </div>
    <div class="links">
      <router-link to="/"><div>Strona główna</div></router-link>
      <router-link to="/wyraz-sprzeciw"><div>Wyraź swój sprzeciw!</div></router-link>
      <router-link to="/podpisz-sie"><div>Poprzyj!</div></router-link>
    </div>
  </nav>
</template>


<style lang="scss">

nav.mobile {
  display: none;
}

nav.mobile {
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: var(--spacing-0);

  .logo-container {
    padding: var(--spacing-6) var(--spacing-0);
    text-align: center;
    .logo {
      max-width: 300px;
      width: 100%;
    }
  }

  div.links {
    flex: 65%;
  }

  div.links a div {
    cursor: pointer;
    padding: var(--spacing-2) var(--spacing-6);
    font-size: var(--fontSize-2);
    text-align: center;
    background: var(--color-work-default);
    font-family: var(--fontFamilyHeadingsBold);
    text-transform: uppercase;
    margin: var(--spacing-3) var(--spacing-0);
    color: var(--color-razem-10);

    &:hover {
      background: var(--color-work-4);
    }
  }

}

@media (max-width: 54rem) {
  nav.mobile {
    display: flex;
  }
}
</style>
