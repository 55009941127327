<template>
  <nav class="desktop">
    <div class="logo-container">
      <router-link to="/"><img src="../img/logo.png" alt="Logo 0% sensu" class="logo"></router-link>
    </div>
    <div class="nav-menu-item">
      <router-link to="/">Strona główna</router-link>
    </div>
    <div class="nav-menu-item">
      <router-link to="/wyraz-sprzeciw">Wyraź swój sprzeciw!</router-link>
    </div>
    <div class="nav-menu-item">
      <router-link to="/podpisz-sie">Poprzyj!</router-link>
    </div>
  </nav>
</template>


<style lang="scss">

nav.desktop {
  display: flex;
}

nav.desktop {
  padding: var(--spacing-6) var(--spacing-6);
  font-family: var(--fontFamilyHeadingsBold);
  font-size: var(--fontSize-2);

  .logo-container {
    width: 280px;
    position: relative;
    padding: var(--spacing-0) var(--spacing-6);

    .logo {
      width: 250px;
      position: absolute;
      top: 0;
      // top: calc(var(--spacing-6) * -1);
    }
  }

  .nav-menu-item {
    margin-top: 30px;
  }

  .logo-text {
    font-size: var(--fontSize-3);
  }

  div {
    padding: var(--spacing-0) var(--spacing-8);
  }

  a {
    color: var(--color-gray-900);

    &:hover {
      color: var(--color-work-default);
    }
  }
}

@media (max-width: 54rem) {
  nav.desktop {
    display: none;
  }
}
</style>
